<template>
  <div id="account-address" role="tabpanel" aria-labelledby="account-address-tab" >
    <loading :show="listLoading" />
    <div class="myaccount-orders shipping-address">
      <h4 class="small-title">{{ $i18n.Address.Title }}</h4>
      <div class="fr-box">
        <a
          href="javascript:void(0)"
          @click="handleAddAddress"
          class="torress-btn torress-btn_dark torress-btn_sm fr"
          ><span>{{ $i18n.Address.Add }}</span></a
        >
        {{ $i18n.Address.Tip }}
      </div>
      <div class="table-responsive">
        <table class="table table-bordered table-hover">
          <tr>
            <th>{{ $i18n.Address.th.Area }}</th>
            <th>{{ $i18n.Address.th.Detailed_Address }}</th>
            <th>{{ $i18n.Address.th.Phone_Number }}Phone Number</th>
            <th>{{ $i18n.Address.th.Area }}Area</th>
            <th width="170"></th>
          </tr>
          <tr v-for="(item, idx) in address">
            <td class="first">
              <div>{{ item.man }}</div>
            </td>
            <td>
              <div>{{ item.address }}</div>
            </td>
            <td>
              <div>{{ item.phone }}</div>
            </td>
            <td>
              {{ item.areaJson[0] }}
            </td>
            <td>
              <a
                href="javascript:void(0)"
                @click="deleteAddress(item.addressId)"
                class="torress-btn torress-btn_dark torress-btn_sm"
                ><span>delete</span></a
              >
              <a
                href="javascript:void(0)"
                @click="changeAddress(idx)"
                style="margin-top: 10px; margin-left: 0"
                class="torress-btn torress-btn_dark torress-btn_sm"
                ><span>Modificar</span></a
              >
            </td>
          </tr>
        </table>
      </div>
    </div>
    <add-address-dom
      :show="addAddressVisible"
      :item="checkedItem"
      @addressOk="getAddress"
    />
  </div>
</template>
<style lang="less">
#account-address {
  position: relative;

  .loading {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.7);
  }
}
</style>
<script>
import loading from '@/views/public/loading.vue'

import addAddressDom from '@/views/public/addAddress.vue'
import { getAddress, deleteAddress } from '@/api'
export default {
  data() {
    return {
      checkedItem: {},
      addAddressVisible: true, //添加地址框是否显示
      address: []
    }
  },
  components: {
    addAddressDom,
    loading
  },
  created() {
    this.getAddress(true)
  },
  methods: {
    handleAddAddress() {
      this.checkedItem = {}
      this.addAddressVisible = true
    },
    deleteAddress(id) {
      deleteAddress({ addressId: id }).then(res => {
        if (res.data.status !== 'fail') {
          this.$toasted.success(this.$i18n.Address.DELETE_SUCCESS)
          this.getAddress(true)
        } else {
          this.$toasted.error(res.data.msg || this.$i18n.Address.DELETE_SUCCESS)
        }
      })
    },
    changeAddress(idx) {
      console.log(idx)
      this.checkedItem = this.address[idx]
      this.addAddressVisible = true
    },
    getAddress(data) {
      this.listLoading = true
      this.addAddressVisible = false
      console.log('getAddress', data !== true)
      if (data !== true) {
        this.listLoading = false
        return
      }
      getAddress({}).then(res => {
        this.address = res.data.data || []
        this.listLoading = false
      })
    }
  }
}
</script>
